import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

const LegalIndex = () => {
  const addExternalScript = (url) => {
    const script = document.createElement('script')
    script.src = url
    script.id = 'CookieDeclaration'
    script.async = true
    const elem = document.getElementById('cookie-declaration-container')
    if (elem) {
      elem.innerHTML = ''
      elem.appendChild(script)
    }
  }

  useEffect(() => {
    addExternalScript('https://consent.cookiebot.com/5eaf7a9d-fc8f-4611-a199-98e49c1075c3/cd.js')
  })

  return (
    <>
      <Helmet title={'Ovation | Legal'}>
        <meta
          name="description"
          content="Practice your public speaking in VR. Ovation provides realistic virtual audiences, tools and feedback to help you grow your skills."
        />
        <meta name="keywords" content="VR, Public, Speaking, Training" />
      </Helmet>
      <div className="wrapper">
        <h2>Legal Documents</h2>
        <div>
          <br /> <br />
          <a href="/VRSpeaking_EULA.pdf" target="_blank">
            End User License Agreement
          </a>
          <br /> <br />
          <a href="/VRSpeaking_PrivacyPolicy.pdf" target="_blank">
            Ovation Privacy Policy
          </a>
          <br /> <br />
          <a href="/VRSpeaking_CookiesPolicy.pdf" target="_blank">
            Cookies Policy
          </a>
          <br /> <br />
          <a target="_blank" onClick={undefined}>
            Cookie Declaration
          </a>
          <br /> <br />
          <div id="cookie-declaration-container"></div>
        </div>
      </div>
    </>
  )
}

export default LegalIndex
